/**
 * @generated SignedSource<<808350a79efc389c976f959c7a620a1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckIsStyleArticle$data = {
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersCheckIsStyleArticle";
};
export type helpersCheckIsStyleArticle$key = {
  readonly " $data"?: helpersCheckIsStyleArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsStyleArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckIsStyleArticle"
};

(node as any).hash = "ecc390de8e43b1f70c74d35d1be2da35";

export default node;
