import { atom } from "jotai";

import type { Props } from "scmp-app/components/advertisement/ad-slots/ad-slot";

type HeaderAdSlotSpec = Pick<Props, "adUnit" | "autoRefreshOptions" | "targeting" | "zone"> & {
  sizes?: googletag.GeneralSize;
};
export const topBannerAdSlotAtom = atom<AdSlotState>(null);
export type AdSlotState = null | {
  desktop: HeaderAdSlotSpec | null;
  mobile: HeaderAdSlotSpec | null;
};
