/**
 * @generated SignedSource<<ae25b96a2a69516cfaaab6b6b9d0a6dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckIsPostiesPage$data = {
  readonly urlAlias: string;
  readonly " $fragmentType": "helpersCheckIsPostiesPage";
};
export type helpersCheckIsPostiesPage$key = {
  readonly " $data"?: helpersCheckIsPostiesPage$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsPostiesPage">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckIsPostiesPage"
};

(node as any).hash = "01a5d88fadd47aa20dac78b1424a0c87";

export default node;
