/**
 * @generated SignedSource<<0f0698fa87e2b671062229c7c0a2ef0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersHasPostiesQuiz$data = {
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersHasPostiesQuiz";
};
export type helpersHasPostiesQuiz$key = {
  readonly " $data"?: helpersHasPostiesQuiz$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersHasPostiesQuiz">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersHasPostiesQuiz"
};

(node as any).hash = "a168e6ece4fd26c403dceefc652a4d06";

export default node;
