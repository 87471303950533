/**
 * @generated SignedSource<<ce8d3dc012b3b2aa5eec81deddddb61c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersStyleSectionContent$data = {
  readonly fullSectionPath: ReadonlyArray<{
    readonly entityId: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersStyleSectionContent";
};
export type helpersStyleSectionContent$key = {
  readonly " $data"?: helpersStyleSectionContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersStyleSectionContent">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersStyleSectionContent"
};

(node as any).hash = "8fb78ac80350a40219d45adb8c0539b5";

export default node;
